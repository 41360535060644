import React from "react";
import BrowseList from "../../../BrowseList/browseList";
import {useTranslation} from "react-i18next";
import {EyeOutlined} from "@ant-design/icons";
import '../styles.css'
const EntitiesList = ({search, shouldReload}) => {
    const {t} = useTranslation();
    return <BrowseList
        search={search}
        getName={(item)=>item.name}
        getDescription={(item)=>t(`entity_type.${item.type}`)}
        route="entities"
        getLinkDescription={(item)=>
            <>
                <span className="text-show">
                    {'Ver entidad'}
                </span>
                <EyeOutlined className="eye-show" style={{paddingLeft: '0.5em'}}/>
            </>
        }
        onItemCLick={(item)=>`/entities/${item.id}`}
        shouldReload={shouldReload}
    />
}
export default EntitiesList;
