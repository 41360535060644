import { t } from "i18next";
import moment from "moment";
import { DatePicker, Descriptions, Form, Input, Select } from "antd";
import Modal from "antd/es/modal/Modal";

export const UpdateEventDataDescriptions = ({onFinishEditing, editEventButton, formRef, event }) => {
console.log("Type->", event)
    const handleChangeUrl = (e) => {

        Modal.warning({
            title: t("eventPage.modalDeleteContact.title"),
            content: (
                <div>
                  <p>
                    {t("eventPage.modalDeleteContact.content.paragraph")} {' '}
                    <a target="_blank" href="https://drive.google.com/drive/u/0/folders/1tGYKnhTb8PDohYQU2RWsW1QyDCDXl5-C">{t("eventPage.modalDeleteContact.content.link")}</a>.
                  </p>
                </div>
              ),
            });
    };

    const typePlaceholder = event?.type?.toLowerCase();


 return (
    <Form ref={formRef} onFinish={onFinishEditing}>
        <Descriptions title={t("eventPage.descriptionPage")} extra={editEventButton()}>
            <Descriptions.Item label={t("eventPage.eventForm.name")}>
                <Form.Item name="summary">
                    <Input placeholder={event.summary} />
                </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventForm.startDate")}>
                <Form.Item name="startsAt">
                    <DatePicker
                        placeholder={moment(event.startsAt).format("DD-MM-YYYY HH:mm")}
                        showTime format="DD-MM-YYYY HH:mm"
                    />
                </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventForm.endDate")}>
                <Form.Item name="endsAt">
                    <DatePicker showTime
                        placeholder={moment(event.endsAt).format("DD-MM-YYYY HH:mm")}
                        format="DD-MM-YYYY HH:mm" />
                </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventForm.region")}>
                <Form.Item name="region">
                    <Select
                    style={{minWidth: '140px'}}
                    placeholder={t(`eventPage.regionInput.${event.region}`)}
                    >
                        <Select.Option value="international">{t("eventPage.regionInput.international")}</Select.Option>
                        <Select.Option value="europe">{t("eventPage.regionInput.europe")}</Select.Option>
                        <Select.Option value="national">{t("eventPage.regionInput.national")}</Select.Option>
                        <Select.Option value="autonomic">{t("eventPage.regionInput.autonomic")}</Select.Option>
                        <Select.Option value="local">{t("eventPage.regionInput.local")}</Select.Option>
                    </Select>
                </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventForm.documentUrl")}>
                <Form.Item name="documentUrl">
                    <Input
                    onChange={handleChangeUrl}
                    placeholder={event.documentUrl ?? t("eventPage.documentUrlInput.noneUrl")}  />
                </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={t("type.type")}>
                <Form.Item name="type">
                    <Select
                    style={{minWidth: '185px'}}
                    placeholder={t(`eventPage.type.${typePlaceholder}` )}
                    >
                        <Select.Option value="company">{t("type.company")}</Select.Option>
                        <Select.Option value="political_party">{t("type.political_party")}</Select.Option>
                        <Select.Option value="org">{t("type.org")}</Select.Option>
                    </Select>
                </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={t("eventPage.eventForm.office")}>
                <Form.Item name="office">
                    <Select style={{minWidth: '185px'}} placeholder={event.office && t(`officeInput.${event.office}`)}>
                        <Select.Option value="spain">{t("officeInput.spain")}</Select.Option>
                        <Select.Option value="portugal">{t("officeInput.portugal")}</Select.Option>
                    </Select>
                </Form.Item>
            </Descriptions.Item>
        </Descriptions>
    </Form>
);
}
