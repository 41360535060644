import React from "react";
import { useTranslation } from "react-i18next";
import {Divider} from "antd";
import ReactQuill from "react-quill";
import './styles.css'
export const NotesSection = ({notes,handleNotesChange}) => {

    const { t } = useTranslation();
    console.log("NotesSelected: " + notes)

    return <>
        <Divider>Notas</Divider>
        <div className="notes-content" style={{paddingBottom: "3em"}}> 
            {/* Pass the handleNotesChange function to the ReactQuill's onChange event */}
            <ReactQuill theme="snow" style={{ height: '15em' }} placeholder={t("formsComponents.placeholer_notes")} value={notes} onChange={handleNotesChange} />
        </div>    </>
}
