import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Button, Form, Input, Tooltip } from "antd";
import {axios} from "../../../../../axiosConfig";
import { useTranslation } from "react-i18next";

const CreateContactForm = ({onFinish}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const {t} = useTranslation();

    const submitForm = (values) => {
        setIsSubmitting(true)
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`,{...values})
            .then(({data})=>{
                setIsSubmitting(false);
                onFinish(values);
                return data.id
            }).then((contactId)=>navigate(`/contacts/${contactId}?createPosition=true`))
    }

    return <>
        <Form onFinish={submitForm} style={{marginTop: "2em"}}>
        <Tooltip title={t('contacts.writeEmailPersonal')}>
            <Form.Item style={{paddingBottom: "1em"}} label={t('contacts.forms.create.mail')} name="email" rules={[{ required: true }]}>
                    <Input />
            </Form.Item>
        </Tooltip>
        <Form.Item style={{paddingBottom: "1em"}} label={t('contacts.forms.create.name')} name="name" rules={[{ required: true }]}>
            <Input/>
        </Form.Item>
        <Form.Item style={{paddingBottom: "1em"}} label={t('contacts.forms.create.surnames')} name="surname">
            <Input/>
        </Form.Item>
        <Form.Item style={{paddingBottom: "1em"}} label={t('contacts.forms.create.address')} name="address">
            <Input/>
        </Form.Item>
        <Form.Item style={{paddingBottom: "1em"}} label={t('contacts.forms.create.postalAddress')} name="zipCode">
            <Input onInput={(e) => {
            e.preventDefault();
            const value = e.target.value.replace(/[^\d]/g, '');
            e.target.value = value;
        }} />
        </Form.Item>
        <Form.Item style={{paddingBottom: "1em"}}   label={t('contacts.forms.create.phone')} name="phone">
            <Input  onInput={(e) => {
            e.preventDefault();
            const value = e.target.value.replace(/[^\d+]/g, '');
            e.target.value = value;
        }} />
        </Form.Item>
        <Form.Item style={{paddingBottom: "1em"}} wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {t("contacts.forms.create.btnCreate")}
            </Button>
        </Form.Item>
    </Form>
    </>
}

export default CreateContactForm;
