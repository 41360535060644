import React, {createContext, useState} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import jwt_decode from "jwt-decode";
import ProtectedRouter from "./app/components/protectedRouter/protectedRouter";
import { useAppDispatch } from './app/hooks';
import Dashboard from "./app/components/views/dashboard/dashboard";
import Login from "./app/components/views/login/login";
import {Provider} from "react-redux";
import {store} from "./app/store";
import {Contacts} from "./app/components/views/contactsList/contacts";
import Entities from "./app/components/views/entitiesList/entities";
import {ConfigProvider, theme} from "antd";
import {Events} from "./app/components/views/eventsList/events";
import Event from "./app/components/views/event/event";
import {attachInterceptors} from "./axiosConfig";
import Contact from "./app/components/views/contact/contact";
import Entity from "./app/components/views/entity/entity";
import {Users} from "./app/components/views/usersList/users";
import ReportsView from "./app/components/views/reports/reports.view";
import {Interactions} from "./app/components/views/interactionsList/interactions";
import Interaction from "./app/components/views/interaction/interaction";
import Documents from "./app/components/views/documents/documents";
import ErrorModalProvider from "./app/providers/ErrorModalProvider/errorModalProvider";
import dayjs from 'dayjs';
import esES from 'antd/es/locale/es_ES';
//  import 'dayjs/locale/es';
import './App.css';


dayjs.locale('es');


export const ThemeContext = createContext();

attachInterceptors(store);
function App() {

  const dispatch = useAppDispatch();
  const [currentTheme, setCurrentTheme] = useState('light');


  const toggleTheme = () => {
    if (currentTheme === 'light') {
      setCurrentTheme('dark');
    } else {
      setCurrentTheme('light');
    }
  };


  const router = createBrowserRouter([
    {
      path: "/",
      element: <ProtectedRouter />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/contacts/:contactId",
          element: <Contact/>
        },
        {
          path: "/contacts",
          element: <Contacts />,
        },
        {
          path: "/entities/:entityId",
          element: <Entity />,
        },
        {
          path: "/entities",
          element: <Entities />,
        },
        {
          path: "/events/:eventId",
          element: <Event/>
        },
        {
          path: "/events",
          element: <Events/>,
        },
        {
          path: "/interactions",
          element: <Interactions/>,
        },
        { 
          path: "/interactions/:interactionId",
          element: <Interaction/>,
        },
        {
          path: "/users",
          element: <Users/>,
        },
        {
          path: "/reports",
          element: <ReportsView/>
        },
        {
          path: "/documents",
          element: <Documents/>
        }
      ],
    },
    {
      path: "/login",
      element: <Login/>,
    },
  ]);


    


  return (
      <Provider store={store}>
        <ErrorModalProvider>
          <ThemeContext.Provider value={toggleTheme}>
            <ConfigProvider
            locale={esES}
                theme={{
              algorithm: currentTheme==='light'?theme.defaultAlgorithm:theme.darkAlgorithm,
              token: {
                colorPrimary: '#2ab20b',
                colorLink: '#2ab20b',
                colorLinkHover: '#0e9b12',
                colorLinkActive: '#44d62f'
              }
            }}>
              <RouterProvider router={router}/>
            </ConfigProvider>
          </ThemeContext.Provider>
        </ErrorModalProvider>
      </Provider>
  );
}

export default App;
