import React, { useState } from "react";
import { Calendar } from "../../calendar/calendar";
import { Button, Card, Modal } from "antd";
import CreateEventForm from "../eventsList/forms/createEvent.form";
import { getEvents } from "../../../../features/calendar/calendarSlice";
import { useAppDispatch } from "../../../hooks";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
    const [showCreateEventModal, setShowCreateEventModal] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const onFormFinish = () => {
        dispatch(getEvents());
        setShowCreateEventModal(false);
    }

    return (
        <>
            <Card style={{ borderRadius: 0 }} title={t("dashboard.title")}>
                {t("dashboard.subtitle")}
            </Card>
            <div className="content-table-entity" style={{ padding: '2em' }}>
                <Card title={t("calendar.title")} extra={<Button onClick={() => setShowCreateEventModal(true)}>{t("calendar.create_event")}</Button>}>
                    <Calendar fullscreen={false}  />
                </Card>
            </div>
            <Modal
                footer={null}
                open={showCreateEventModal}
                onCancel={() => setShowCreateEventModal(false)}>
                <CreateEventForm onFinish={onFormFinish} />
            </Modal>
        </>
    )
}

export default Dashboard;
