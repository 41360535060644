import React, {useEffect, useState} from "react";
import {Avatar, Button, Card, Input, Modal, Segmented} from "antd";
import { useTranslation } from "react-i18next";
import InteractionsList from "./lists/interactions.list";
import { PhoneOutlined, SearchOutlined, WhatsAppOutlined } from "@ant-design/icons";
import CreateInteractionForm from "../contact/forms/createInteraction.form";
import CreateEventForm from "../eventsList/forms/createEvent.form";
import './styles.css'

const { Search } = Input;

export const Interactions  = () => {

const {t} = useTranslation();

    const [showModal,setShowModal] = useState({
        createInteraction: false,
        updateInteraction: false
    })

    const [search, setSearch] = useState('');
    const [shouldReload, setShouldReload] = useState(0);
    const [timeoutId, setTimeoutId] = useState();
    const [createInteractionModalContent,setCreateInteractionModalContent] = useState();

    const getCreateInteractionModalContent = (type, onFinish) => {
        return {
            'calendar_event': <CreateEventForm onFinish={onFinish}/>,
            'call':<CreateInteractionForm onFinish={onFinish} type="phone"/>,
            'conversation':<CreateInteractionForm onFinish={onFinish} type="in_person"/>,
        }[type]
    }

    const handleChange = (event) => {
        // Clear the timeout if it is already set
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new timeout to perform the search after 3 seconds
        setTimeoutId(setTimeout(() => {
            setSearch(event.target.value)
        }, 200));
    }

    const onFinishCreateInteraction = () => {
        setShouldReload(shouldReload+1);
        setShowModal({...showModal,createInteraction: false});
    }

    useEffect(()=>{
        setCreateInteractionModalContent(getCreateInteractionModalContent('call',onFinishCreateInteraction))
    }, [])

    return <>
        <Card style={{borderRadius:0}} title={t("interactions.title")}>
            {t("interactions.descriptionPage")}
        </Card>
        <Card className="card-content-contact" style={{margin: '2em', paddingLeft: '4em', paddingRight: '4em', paddingTop: '2em', paddingBottom: '2em'}}>
            <div style={{gap: '2em', display: "flex", flexDirection: "column"}}>
                <div className="header-search-button" style={{display:'flex', gap: '2em'}}>
                    <Search placeholder={t("interactions.inputSearchTxt")} onChange={handleChange}   enterButton={ <SearchOutlined  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />} />
                    <Button 
                        onClick={()=>setShowModal({...showModal, createInteraction: true})}
                    >{t("interactions.create_iteraction_button")}</Button>
                </div>
                <Modal
                    footer={null}
                    open={showModal.createInteraction}
                    onCancel={()=>setShowModal({...showModal,createInteraction: false})}>
                    <div style={{width: '100%', display: 'flex',padding:'1em',justifyContent:'center'}}>
                        <Segmented
                            onChange={(value)=>setCreateInteractionModalContent(getCreateInteractionModalContent(value, onFinishCreateInteraction))}
                            options={[
                                {
                                    label: (
                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 4 }}>
                                            <Avatar style={{ backgroundColor: '#f56a00' }} icon={<PhoneOutlined/>}></Avatar>
                                            <span>{t("interactions.forms.display_data.type.phone")}</span>
                                        </div>
                                    ),
                                    value: 'call',
                                },
                                {
                                    label: (
                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 4 }}>
                                            <Avatar style={{ backgroundColor: '#87d068' }} icon={<WhatsAppOutlined />} />
                                            <span>{t("interactions.forms.display_data.type.in_person")}</span>
                                        </div>
                                    ),
                                    value: 'conversation',
                                },
                            ]}
                        />
                    </div>
                    {createInteractionModalContent}
                </Modal>
                <div>
                    <InteractionsList search={search} shouldReload={shouldReload}/>
                </div>
            </div>
        </Card>
    </>
}
