import React from "react";
import {useTranslation} from "react-i18next";
import BrowseList from "../../../BrowseList/browseList";
import { EyeOutlined } from "@ant-design/icons";
import '../styles.css'

const ContactsList = ({search,shouldReload,queryParams,setContactsList,contactsList}) => {
    const {t} = useTranslation();

    return <BrowseList
        search={search}
        getName={(item) => {
            const contactId = item.id;
            const isContactAlreadyInList = contactsList && contactsList.some(
              (contact) => contact.id === contactId
            );

            if (!isContactAlreadyInList) {
              setContactsList((prevContacts) => [...prevContacts, item]);
            };
            return `${item.name} ${item.surname || ""}`;
          }}
        getDescription={(item) => (
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {item?.positions?.length > 0 ? (
                    item.positions.some(position => !position.endsAt) ?
                        item.positions.filter(position => !position.endsAt).map(position => (
                            <>
                                {position?.title} {position?.political_party ? `| ${position?.political_party}` : ''} {position?.phone ? `| ${position?.phone}` : ''}
                            </>
                        )) :
                        <>
                            {
                                (() => {
                                    const latestPosition = item.positions.reduce((latest, position) =>
                                        !latest.endsAt || position.endsAt > latest.endsAt ? position : latest
                                    );
                                    return (
                                        <>
                                            {latestPosition?.title} {latestPosition?.political_party ? `| ${latestPosition?.political_party}` : ''} {latestPosition?.phone ? `| ${latestPosition?.phone}` : ''}
                                        </>
                                    );
                                })()
                            }
                        </>
                ) : null}
            </div>

        )
        }
        route="contacts" shouldReload={shouldReload}
        getLinkDescription={(item)=>
            <>
                <span className="text-show">
                    {t("contacts.view_contact")}
                </span>
                <EyeOutlined className="eye-show" style={{paddingLeft: '0.5em'}}/>
            </>
        }
        onItemCLick={(item)=>`/contacts/${item.id}`}
        queryParams={queryParams}

    />
}
export default ContactsList;
