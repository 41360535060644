import InviteUserForm from "./forms/inviteUser.form";
import { t } from "i18next";
import {useState} from "react";
import {Button, Card, Input, Modal} from "antd";
import UsersList from "./lists/users.list";
import './styles.css'
const { Search } = Input;

export const Users  = () => {
    const [showCreateContactModal,setShowCreateContactModal] = useState(false);
    const [search, setSearch] = useState('');
    const [shouldReload, setShouldReload] = useState(0);
    const [timeoutId, setTimeoutId] = useState();
    const onFinish = () => {
        setShowCreateContactModal(false);
        setShouldReload(shouldReload+1);
    }

    const handleChange = (event) => {
        // Clear the timeout if it is already set
        if (timeoutId) {
            clearTimeout(timeoutId);
        };

        // Set a new timeout to perform the search after 3 seconds
        setTimeoutId(setTimeout(() => {
            setSearch(event.target.value)
        }, 200));
    };

    return <>
        <Card style={{borderRadius:0}} title={t("usersPage.title")}>
        {t("usersPage.descriptionPage")}
        </Card>
        <Card className="card-content-contact" style={{margin: '2em', paddingLeft: '4em', paddingRight: '4em', paddingTop: '2em', paddingBottom: '2em'}}>
            <div style={{gap: '2em', display: "flex", flexDirection: "column"}}>
                <div className="header-search-button" style={{display:'flex', gap: '2em'}}>
                    <Search placeholder="input search text" onChange={handleChange} enterButton />
                    <Button
                        onClick={()=>setShowCreateContactModal(true)}
                    >{t("usersPage.inviteContact")}</Button>
                </div>
            <Modal
                footer={null}
                open={showCreateContactModal}
                onCancel={()=>setShowCreateContactModal(false)}>
                <InviteUserForm onFinish={onFinish}/>
            </Modal>
        <div>
            <UsersList search={search} shouldReload={shouldReload}/>
        </div>
            </div>
        </Card>
    </>
}
