import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { axios } from "../../../../../axiosConfig";
import SearchInputWrite from "../../../searchInput/searchInputWrite";
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const CreateEntityForm = ({ onFinish }) => {
    console.log("CreateEntityForm", onFinish);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);

    const onFormFinish = async (values) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities`, values);
            
            if (response && response.data && response.data.id) {
                     // Check current location before browsing
                    if (location.pathname === "/entities") {
                        navigate(`/entities/${response.data.id}`);
                    };
                };

            onFinish(response.data);
            // do something with the created entity
        } catch (error) {
            // display the error message to the user
            form.setFields([
                {
                    name: 'name',
                    errors: [error.message]
                }
            ]);
        }
    };

    const initialValues = {
        id: "",
        name: "",
    }

    return (
        <Form
            style={{ display: "flex", flexDirection: "column", gap: "1em", paddingTop: "1em" }}
            form={form}
            name="entity-form"
            onFinish={onFormFinish}
        >
            <Form.Item
                label={`${t('entity.createModifyEntityModal.nameTitle')}`}
                name="name"
                rules={[{ required: true }]}
            >
                <SearchInputWrite url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities`}
                    valueInput='name'
                    label='name'
                    initialValue={{ value: initialValues?.name, text: initialValues?.name }} 
                />
            </Form.Item>
            <Form.Item
                label={`${t('entity.createModifyEntityModal.urlTitle')}`}
                name="url"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="type"
                label={`${t('entity.createModifyEntityModal.entity.title')}`}
                rules={[{ required: true }]}
            >
                <Select
                    options={[
                        { value: 'NGO', label: `${t('entity.createModifyEntityModal.entity.ong')}` },
                        { value: 'company', label: `${t('entity.createModifyEntityModal.entity.company')}` },
                        { value: 'political_party', label: `${t('entity.createModifyEntityModal.entity.political_party')}` },
                        { value: 'ORG', label: `${t('entity.createModifyEntityModal.entity.organism_istitution')}` },
                    ]}
                    style={{ minWidth: "10em" }}
                />
            </Form.Item>
            <Form.Item
                name="segment"
                label={`${t('entity.createModifyEntityModal.segmentation.title')}`}
                rules={[{ required: true }]}
            >
                <Select
                    options={[
                        { value: 'Energías Limpias', label: `${t('entity.createModifyEntityModal.segmentation.clean_energies')}` },
                        { value: 'Sector Energía', label: `${t('entity.createModifyEntityModal.segmentation.energy_sector')}` },
                        { value: 'Financieras', label: `${t('entity.createModifyEntityModal.segmentation.financial')}` },
                        { value: 'Energías Sucias', label: `${t('entity.createModifyEntityModal.segmentation.dirty_energies')}` },
                        { value: 'Energía Colaborativa', label: `${t('entity.createModifyEntityModal.segmentation.collaborative_electrical')}` },
                        { value: 'Eléctricas', label: `${t('entity.createModifyEntityModal.segmentation.energy')}` },
                        { value: 'Moda', label: `${t('entity.createModifyEntityModal.segmentation.moda')}` },
                        { value: 'Aseguradoras', label: `${t('entity.createModifyEntityModal.segmentation.insurers')}` },
                        { value: 'Grandes superficies', label: `${t('entity.createModifyEntityModal.segmentation.large_surfaces')}` },
                        { value: 'Ambiental', label: `${t('entity.createModifyEntityModal.segmentation.environmental')}` },
                        { value: 'Política', label: `${t('entity.createModifyEntityModal.segmentation.political')}` },
                        { value: 'Social', label: `${t('entity.createModifyEntityModal.segmentation.social')}` },
                        { value: 'Otras', label: `${t('entity.createModifyEntityModal.segmentation.others')}` },
                    ]}
                    style={{ minWidth: "10em" }}
                />
            </Form.Item>
            <Form.Item
                name="region"
                label={`${t('entity.createModifyEntityModal.region.title')}`}
                rules={[{ required: true }]}
            >
                <Select
                    options={[
                        { value: 'international', label: `${t('entity.createModifyEntityModal.region.international')}` },
                        { value: 'europe', label: `${t('entity.createModifyEntityModal.region.europe')}` },
                        { value: 'national', label: `${t('entity.createModifyEntityModal.region.national')}` },
                        { value: 'autonomic', label: `${t('entity.createModifyEntityModal.region.autonomic')}` },
                        { value: 'local', label: `${t('entity.createModifyEntityModal.region.local')}` },
                    ]}
                    style={{ minWidth: "10em" }}
                />
            </Form.Item>
            <Form.Item name="address" label={`${t('entity.createModifyEntityModal.addressTitle')}`}>
                <Input />
            </Form.Item>
            <Form.Item name="taxId" label={`${t('entity.createModifyEntityModal.cifTitle')}`}>
                <Input />
            </Form.Item>
            <Form.Item name="zipCode" label={`${t('entity.createModifyEntityModal.zipCodeTitle')}`}>
                <Input />
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "center", paddingTop: "1em", paddingBottom: "1em" }}>
                <Button type="primary" htmlType="submit">
                    {`${t('entity.createModifyEntityModal.btnText')}`}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateEntityForm;
