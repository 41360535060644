import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-http-backend";
import {formatDate, lastUpdatedDifference} from "./date";

const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric"
};

export const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        lng: 'es',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    })
    .then(()=>{
        i18n.services.formatter.add('POSITION_STARTS_AT', (startsAt, lng, options) => {
            return formatDate(new Date(startsAt))
        });

        i18n.services.formatter.add('POSITION_ENDS_AT', (endsAt, lng, options) => {
            return endsAt?(endsAt && (new Date(endsAt))
                .toLocaleDateString("es-ES", dateOptions)):' Actualidad'
        });

        i18n.services.formatter.add('LAST_UPDATED', (date, lng, options) => {
            return lastUpdatedDifference(date)
        });
    });



