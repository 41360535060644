import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Button, Card, Input, Modal} from 'antd';
import CreateEntityForm from "./forms/createEntity.form";
import EntitiesList from "./lists/entities.list";
import { SearchOutlined } from '@ant-design/icons';
import './styles.css';
const { Search } = Input;

const Entities = () => {
    const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);
    const [search, setSearch] = useState('');
    const [timeoutId, setTimeoutId] = useState();
    const [shouldReload, setShouldReload] = useState(0);
    const {t} = useTranslation();

    const onFormFinish = () => {
        setShouldReload(shouldReload + 1);
        setShowCreateEntityModal(false);
    }

    const handleChange = (event) => {
        // Clear the timeout if it is already set
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        console.log('change', event.target.value)
        // Set a new timeout to perform the search after 3 seconds
        setTimeoutId(setTimeout(() => {
            setSearch(event.target.value)
        }, 200));
    }

    return (
        <>
            <Card style={{borderRadius:0}} title={`${t('entitiesPage.pageTitle')}`}>
            {t('entitiesPage.pageTitle')}
            </Card>
            <Card className='card-content-contact' style={{margin: '2em', paddingLeft: '4em', paddingRight: '4em', paddingTop: '2em', paddingBottom: '2em'}}>
                <div style={{gap: '2em', display: "flex", flexDirection: "column"}}>
                    <div  className='header-search-button' style={{display:'flex', gap: '2em'}}>
                    <Search placeholder={t("entitiesPage.inputSearchTxt")} onChange={handleChange} enterButton  />
    
                    <Button onClick={() => setShowCreateEntityModal(true)}>
                    {t('entitiesPage.btnCreateEntity')}
                    </Button>
                </div>
                <Modal footer={null} open={showCreateEntityModal} onCancel={() => setShowCreateEntityModal(false)} title={t('entitiesPage.btnCreateEntity')}>
                    <CreateEntityForm onFinish={()=>onFormFinish()} />
                </Modal>
            <div>
                <EntitiesList search={search} shouldReload={shouldReload}/>
            </div>
                </div>
            </Card>
        </>
    );
};

export default Entities;
