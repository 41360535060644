import { Link } from "react-router-dom";
import { t } from "i18next";
import { Button, Form, Switch } from "antd";
import { CheckOutlined, CloseOutlined, DeleteOutlined, SaveOutlined, UserAddOutlined } from "@ant-design/icons";
import SearchInput from "../../../searchInput/searchInput";
import '../event.styles.css';

export const EventHooks = ({ 
    autoSaveNotes, 
    setAutoSaveNotes, 
    handleNotesSubmit, 
    savingNotes, 
    addContactToEventCallback, 
    addEntityToEventCallback, 
    editingEvent, 
    setEditingEvent, 
    setShowDeleteEventModal, 
    saveEventLoading, 
    handleSubmitEvent }) => {

//
    const genNotesExtra = () => (
        <div style={{ display: "flex", gap: '1em', alignItems: "center" }}>
            <Switch
                defaultChecked={autoSaveNotes}
                onChange={() => setAutoSaveNotes(!autoSaveNotes)}
                disabled
            />
            <Button
                className="btn-accordion"
                type="primary"
                icon={<SaveOutlined />}
                loading={savingNotes}
                disabled={autoSaveNotes}
                onClick={(event) => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                    handleNotesSubmit();
                }}
            >
                {t('eventPage.txtSaved')}
            </Button>
        </div>
    );

    //
    const genContactsExtra = () => (<div onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
    }}>
        <Form style={{ display: 'flex', gap: '1em' }} onFinish={addContactToEventCallback}>
            <Form.Item
                name="contactId"
            >
                <SearchInput
                    className="search-input-event"
                    url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`}
                    mode="single"
                    placeholder={`${t('eventPage.placeholderAssistants')}`}
                    style={{ width: '30em' }}
                />
            </Form.Item>
            <Button
                className="btn-accordion"
                type="primary"
                htmlType="submit"
                icon={<UserAddOutlined />}
            >{t('eventPage.btnAddAssistant')}</Button>
            <div style={{ display: "flex", alignItems: "center", gap: '.5em' }}> {t('or')}
                <Link to="/contacts">{t('eventPage.linkBtnAssitant')}</Link>
            </div>
        </Form>
    </div>);


    //
    const genEntitiesExtra = () => (<div onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
    }}>
        <Form style={{ display: 'flex', gap: '1em' }} onFinish={addEntityToEventCallback}>
            <Form.Item
                name="entityId"
            >
                <SearchInput
                    className="search-input-event"
                    url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/entities`}
                    mode="single"
                    placeholder={`${t('eventPage.placeholderEntity')}`}
                    style={{ width: '30em' }}
                />
            </Form.Item>
            <Button
                className="btn-accordion"
                type="primary"
                htmlType="submit"
                icon={<UserAddOutlined />}
                onClick={(event) => {
                    addEntityToEventCallback();
                }}
            >{t('eventPage.btnAddEntity')}</Button>
            <div style={{ display: "flex", alignItems: "center", gap: '.5em' }}> {t('or')}
                <Link to="/entities">{t('eventPage.linkBtnEntity')}</Link>
            </div>
        </Form>
    </div>
    );

    //
    const editEventButton = () => {
        return  !editingEvent ? <Button type="default" onClick={()=>setEditingEvent(true)}>{t('eventPage.btnEdit')}</Button>:
            <div style={{display: "flex", gap: ".2em"}}>
                <Button onClick={()=>setEditingEvent(false)} type="dashed"  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }} shape="circle" icon={<CloseOutlined />} />
                <Button onClick={()=>setShowDeleteEventModal(true)} type="default"   style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }} shape="circle" icon={<DeleteOutlined />} />
                <Button type="primary" loading={saveEventLoading}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} shape="circle" icon={<CheckOutlined />} onClick={handleSubmitEvent}/>
            </div>
     };


    return {
        genNotesExtra,
        genContactsExtra,
        genEntitiesExtra,
        editEventButton
    };
};