import CreateContactForm from "./forms/createContact.form";
import { useState } from "react";
import { Button, Card, Input, Modal } from "antd";
import ContactsList from "./lists/contacts.list";
import { useTranslation } from "react-i18next";
import './styles.css'
const { Search } = Input;

export const Contacts = () => {
    const [showCreateContactModal, setShowCreateContactModal] = useState(false);
    const [search, setSearch] = useState('');
    const [shouldReload, setShouldReload] = useState(0);
    const [timeoutId, setTimeoutId] = useState();
    const { t } = useTranslation();
    const onFinish = () => {
        setShowCreateContactModal(false);
        setShouldReload(shouldReload + 1);
    }

    const handleChange = (event) => {
        // Clear the timeout if it is already set
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new timeout to perform the search after 3 seconds
        setTimeoutId(setTimeout(() => {
            setSearch(event.target.value)
        }, 200));
    }

    return <>
        <Card style={{ borderRadius: 0 }} title={t("contacts.title")}>
            {t("contacts.subtitle")}
        </Card>
        <Card className="card-content-contact" style={{ margin: '2em', paddingLeft: '4em', paddingRight: '4em', paddingTop: '2em', paddingBottom: '2em' }}>
            <div style={{ gap: '2em', display: "flex", flexDirection: "column" }}>
                <div className="header-search-button" style={{ display: 'flex', gap: '2em' }}>
                    <Search placeholder={t("contacts.search_contact_placeholder")} onChange={handleChange} enterButton />
                    <Button
                        onClick={() => setShowCreateContactModal(true)}
                    >{t("contacts.create_contact")}</Button>
                </div>
                <Modal
                    title={t("contacts.modalTitle")}
                    footer={null}
                    open={showCreateContactModal}
                    onCancel={() => setShowCreateContactModal(false)}>
                    <CreateContactForm onFinish={onFinish} />
                </Modal>
                <div>
                    <ContactsList contactsList={''} setContactsList={() => { }} search={search} shouldReload={shouldReload} />
                </div>
            </div>
        </Card>
    </>
}
