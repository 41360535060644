import {useEffect, useState} from "react";
import Meta from "antd/es/card/Meta";
import {Link} from "react-router-dom";
import {useAppDispatch} from "../../hooks";
import {useSelector} from "react-redux";
import {getEvents, selectCalendar} from "../../../features/calendar/calendarSlice";
import {Avatar, Badge, Calendar as AntdCalendar, Card, List, Skeleton} from "antd";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import './calendar.css';

dayjs.locale('es');

export const Calendar = () => {
    const dispatch = useAppDispatch();
    const calendar = useSelector(selectCalendar);

    const [selectedDate, setSelectedDate] = useState(dayjs());

    const handleCalendarChange = (date) => {
      setSelectedDate(date);
    };


    useEffect(()=>{
            dispatch(getEvents())
        },
        [])

    useEffect(()=>{
            console.log('calendar update',calendar)
        },
        [calendar])


   

    const getListData = (value) => {
        return calendar
            .filter(event=>event.startsAt?.split("T")[0]===value.format()?.split("T")[0])
            .map(event=>{
                return {
                    type: 'success',
                    content: event.summary,
                    id: event.id,
                    status: event.status,
                    startsAt: new Date(event.startsAt)
                }
            })
    }
    const getPopoverContent = (eventId) => {
        const eventData = calendar.find(event=>event.id===eventId);
        return <Card
            style={{ width: 300, marginTop: 16 }}
            actions={[
                <SettingOutlined key="setting" />,
                <EditOutlined key="edit" />,
                <EllipsisOutlined key="ellipsis" />,
            ]}
        >
            <Skeleton loading={false} avatar active>
                <Meta
                    title={eventData.summary}
                    description={eventData.description}
                />
                <List
                    itemLayout="horizontal"
                    dataSource={eventData.attendees}
                    renderItem={(item, idx) => (
                        <List.Item  key={idx}>
                            <List.Item.Meta
                                avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                title={item.displayName??item.email}
                            />
                        </List.Item>
                    )}
                />
            </Skeleton>
        </Card>
    }

    const getEventColor = (event) => {
        return event.startsAt>new Date()?'blue': {
            "pending": 'red',
            "confirmed": 'green',
        } [event.status]
    }

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events no-scrollbar">
                {listData.map(item => (
/*
                    <Popover placement="left" content={()=>getPopoverContent(item.id)} title={"Evento"} className="calendar-details-popover">
*/
                    <li key={item.id}className="calendar-event-item" >
                        <Link  className="badge-content" to={`/events/${item.id}`}  >
                        <Badge  status={item.type} text={item.content} color={getEventColor(item)} 
                         style={{backgroundColor: getEventColor(item) === 'red' ? '#ff9393' : null, padding: '1px 2px'  }}/>
                        </Link>
                    </li>
/*
                    </Popover>
*/
                ))}
            </ul>
        );
    };

    
    return calendar&&<AntdCalendar value={selectedDate} onChange={handleCalendarChange} mode={"month"} dateCellRender={dateCellRender} />

}
