import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../searchInput/searchInput";
import { axios } from "../../../../../axiosConfig";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../features/user/userSlice";
import CreateContactForm from "../../contactsList/forms/createContact.form";
import { Button, DatePicker, Form, Input, Modal, Select, Tooltip} from "antd";

const CreateEventForm = ({ onFinish }) => {

    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;
    const user = useSelector(selectUser);
    const [supervisors, setSupervisors] = useState([]);
    const [showCreateContactModal, setShowCreateContactModal] = useState(false);
    const [collectId, setCollectId] = useState("");

    const volunteerFields = () => {
        return (
            user?.role === 'volunteer' &&

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item
                    label={t("calendar.forms.create.supervisor")}
                    name="supervisorId" rules={[{ required: false }]} >
                    <Select
                        style={{ minWidth: 150, maxWidth: 400, marginLeft: 'auto' }}
                        options={supervisors}
                    />
                </Form.Item>
            </div>
        )
    }

    const validatedFields = () => {
        return (
            user?.role !== 'volunteer' &&
            <Tooltip title={t('contacts.tooltipEmailGreenpeace')}>
                <Form.Item style={{ paddingBottom: "1em" }} label={t("calendar.forms.create.email")} name="attendees" rules={[{ required: true }]}>
                    <SearchInput
                        url={`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/contacts`}
                        mode="multiple"
                        createButtonName="Crear contacto"
                        createButtonCallback={() => setShowCreateContactModal(true)}
                    />
                </Form.Item>
            </Tooltip>
        )
    }

    const retrieveVolunteerSupervisors = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/volunteer-supervisor`)
            .then(({ data }) => setSupervisors(data.data.map(({ id, name }) => {
                return {
                    value: id,
                    label: name
                }
            })))
    }

    const submitForm = (values) => {
        // Format the date range from the form values
        const dateRange = values.date;
        const start = dateRange[0];
        const end = dateRange[1];

        // Set the isSubmitting flag to true
        setIsSubmitting(true);

        // Submit the form values to the /v1/events route
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/v1/events`, {
            summary: values.summary,
            startsAt: start,
            endsAt: end,
            attendees: values.attendees || [],
            region: values.region ? values.region : 'none',
            type: values.type ? values.type : 'company',
            office: values.office ? values.office : 'spain',
            supervisorId: values.supervisorId ? values.supervisorId : ""
        })
            .then((response) => {
                // Set the isSubmitting flag to false and call the onFinish callback
                setIsSubmitting(false);
                onFinish(values);
                const eventId = response.data.event.id;
                setCollectId(eventId)
                form.resetFields();
            })
    };

    useEffect(() => {
        if (collectId) {
            navigate(`/events/${collectId}`);
        };
    }, [collectId]);


    useEffect(() => {
        retrieveVolunteerSupervisors();
    }, [])

    return <><Form form={form}
        onFinish={submitForm} style={{ marginTop: "2em" }}>
        <Form.Item style={{ paddingBottom: "1em" }} label={t("calendar.forms.create.name")} name="summary" rules={[{ required: true }]}>
            <Input />
        </Form.Item>
        <Form.Item style={{ paddingBottom: "1em" }} label={t("calendar.forms.create.date")} name="date" rules={[{ required: true }]}>
            <RangePicker
                showTime
                format="DD-MM-YYYY HH:mm"
                minuteStep={5}
            // disabledDate={currentDate => currentDate && currentDate < moment().startOf('day')}
            />
        </Form.Item>
        
        {validatedFields()}
        <Form.Item rules={[{ required: true }]} style={{ paddingBottom: "1em" }} name="region" label={t("eventForm.region")}   >
            <Select
                options={[
                    { value: 'international', label: t("regionInput.international") },
                    { value: 'europe', label: t("regionInput.europe") },
                    { value: 'national', label: t("regionInput.national") },
                    { value: 'autonomic', label: t("regionInput.autonomic") },
                    { value: 'local', label: t("regionInput.local") },
                ]}
                style={{ minWidth: "10em" }}
            />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} style={{ paddingBottom: "1em" }} name="type" label={t("type.type")}   >
            <Select
                options={[
                    { value: 'company', label: t("type.company") },
                    { value: 'ngo', label: t("type.ngo") },
                    { value: 'political_party', label: t("type.political_party") },
                    { value: 'org', label: t("type.org") }
                ]}
                style={{ minWidth: "10em" }}
            />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} style={{ paddingBottom: "1em" }} name="office" label={t("eventForm.office")}  
        initialValue={"spain"}
        >
            <Select
                defaultValue={"spain"}
                value={"spain"}
                options={[
                    { value: 'spain', label: t("officeInput.spain") },
                    { value: 'portugal', label: t("officeInput.portugal") },
                ]}
                style={{ minWidth: "10em" }}
            />
        </Form.Item>
        {volunteerFields()}
        <Form.Item style={{ paddingBottom: "0em", display: "flex", justifyContent: "center", paddingTop: "1em" }}>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {t("calendar.forms.create.confirm")}
            </Button>
        </Form.Item>
    </Form>
        <Modal
            title={t("contacts.create_contact")}
            footer={null}
            open={showCreateContactModal}
            onCancel={() => setShowCreateContactModal(false)}>
            <CreateContactForm onFinish={() => setShowCreateContactModal(false)} />
        </Modal>
    </>
}

export default CreateEventForm;
