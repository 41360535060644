import React, { useState } from 'react';
import {Select} from 'antd';
import {axios} from "../../../axiosConfig";
let timeout;
let currentValue;
const fetch = (value, callback, searchUrl, label, valueInput) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    const fake = (searchTerm) => {
        axios.get(searchUrl, {
            params: {
                search: searchTerm,
                limit: 5,
                offset: 0
            },
        })
            .then(({data}) => {
                console.log('data', data);
                if (currentValue === value) {
                    const result = data.data;
                    const newVal = result.map((item) => ({
                        value: valueInput ? item[valueInput]: item.id,
                        text: label ? (`${item[label]}`): item.name ?(`${item.name} ${item.surname||''} ${item.email?`(${item.email})`:''}`):(item.email||''),
                    }));
                    callback(newVal);
                }
            });
    };
    timeout = setTimeout(()=>fake(value), 200);
};

const SearchInputWrite = (props) => {
    const [data, setData] = useState(props.initialValue ? [props.initialValue] : []);
    const [value, setValue] = useState(props.initialValue?.value);

    const handleSearch = (newValue) => {
        if (newValue) {
            fetch(newValue, (newData) => {
                if (newData.length === 0) {
                    setData([{ value: newValue, text: newValue }]);
                } else {
                    setData(newData);
                }
            }, props.url, props.label, props.valueInput);
        } else {
            setData([]);
        }
    };

    const handleChange = (newValue) => {
        props.onChange(newValue);
        setValue(newValue);
    };

    return (
        <Select
            className={props.className}
            showSearch
            value={value}
            mode={props.mode}
            placeholder={props.placeholder}
            style={props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            options={(data || []).map((d) => ({
                value: d.value,
                label: d.text,
            }))}
        />
    );
};

export default SearchInputWrite;
