import { useState} from "react";
import { t } from "i18next";
import {Button, Card, Input, Modal} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import EventsList from "./lists/events.list";
import CreateEventForm from "./forms/createEvent.form";
import './styles.css';
const { Search } = Input;

export const Events  = () => {
    
    const [showCreateEventModal,setShowCreateEventModal] = useState(false);
    const [search, setSearch] = useState('');
    const [shouldReload, setShouldReload] = useState(0);
    const [timeoutId, setTimeoutId] = useState();

    const handleChange = (event) => {
        // Clear the timeout if it is already set
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new timeout to perform the search after 3 seconds
        setTimeoutId(setTimeout(() => {
            setSearch(event.target.value)
        }, 200));
    }

    const onFormFinish = () => {
        setShouldReload(shouldReload+1);
        setShowCreateEventModal(false);
    }

    return <>
        <Card style={{borderRadius:0}} title={t("eventsPage.title")}>
            {t("eventsPage.descriptionPage")}
        </Card>
        <Card className="card-content-contact" style={{margin: '2em', paddingLeft: '4em', paddingRight: '4em', paddingTop: '2em', paddingBottom: '2em'}}>
            <div style={{gap: '2em', display: "flex", flexDirection: "column"}}>
                <div className="header-search-button" style={{display:'flex', gap: '2em'}}>
                    <Search placeholder={t("eventsPage.inputSearchTxt")} onChange={handleChange}  enterButton={ <SearchOutlined  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}  />
                    <Button
                        onClick={()=>setShowCreateEventModal(true)}
                    >{t("eventsPage.createEvent")}</Button>
                </div>
                <Modal
                    title={t("eventsPage.createEvent")}
                    footer={null}
                    open={showCreateEventModal}
                    onCancel={()=>setShowCreateEventModal(false)}>
                    <CreateEventForm onFinish={onFormFinish}/>
                </Modal>
                <div>
                    <EventsList search={search} shouldReload={shouldReload}/>
                </div>
            </div>
        </Card>
    </>
}
